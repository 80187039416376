<template>
  <section class="chat-contact" :class="messageClass">
    <div class="chat-contact__header">
      <!-- bot name + status -->
      <div class="status-bot">
        <span class="bot-name">
          <span class="name">
            {{ contact.botName }} {{ contact.about }}
            <div v-if="contact.staging" class="env-tag ml-2">staging</div>
          </span>
        </span>
        <vs-chip :color="chatStatusColor" class="bg-chip-transparent">
          {{ chatStatusText }}
        </vs-chip>
      </div>
      <!-- timestamp + priority changed + unseen -->
      <div class="timestamp">
        <KonaHelpTooltip
          v-show="contact.outdatedPriority"
          :text="lang.helpTooltips.contact.outdatedPriority[languageSelected]"
          position="bottom"
          icon="AlertTriangleIcon"
        />
        <vx-tooltip :text="timestamp" position="top">
          <vs-chip color="danger" class="mr-2" v-if="unseenMsg">
            {{ unseenMsg }}
          </vs-chip>
          <span class="timestamp__text">
            {{ timeElapsed }}
          </span>
        </vx-tooltip>
      </div>
    </div>

    <div class="chat-contact__body">
      <!-- platform + user name -->
      <div class="platform-user">
        <img
          style="height: 18px; margin-right: 8px"
          :src="channelLogo(contact.platform)"
          :alt="contact.platform"
          :title="contact.platform"
        />
        <span class="platform">{{ platformName }}:&nbsp;</span>
        <text-highlight :queries="[highlight]" class="user">
          {{ fullname }}
        </text-highlight>
      </div>
      <!-- intent -->
      <vx-tooltip
        :text="contact.intents[0]"
        position="right"
        v-if="contact.intents && contact.intents.length"
      >
        <div class="intent"></div>
      </vx-tooltip>
      <vx-tooltip
        :text="
          contact.taggedPriorityEvent.dialogTag ||
            contact.taggedPriorityEvent.intent
        "
        position="right"
        v-if="
          selectedCategory &&
            selectedCategory.subpath === 'taggedPriority' &&
            contact.taggedPriorityEvent &&
            (contact.taggedPriorityEvent.dialogTag ||
              contact.taggedPriorityEvent.intent)
        "
      >
        <vs-chip
          v-if="contact.taggedPriority && contact.taggedPriorityEvent"
          :style="getStyle(contact.taggedPriorityColor,contrastColor(contact.taggedPriorityColor))"
        >
          <vs-avatar color="primary" :text="`${contact.taggedPriority}`" />
          {{
            lang.botMaker.addBot.priorities.tableHeaders.priority[
              languageSelected
            ]
          }}
        </vs-chip>
      </vx-tooltip>
      <vs-chip
        v-else-if="
          selectedCategory &&
            selectedCategory.subpath === 'taggedPriority' &&
            contact.taggedPriority &&
            contact.taggedPriorityEvent
        "
        :style="getStyle(contact.taggedPriorityColor,contrastColor(contact.taggedPriorityColor))"
      >
        <vs-avatar color="" :text="`${contact.taggedPriority}`" />
        {{
          lang.botMaker.addBot.priorities.tableHeaders.priority[
            languageSelected
          ]
        }}
      </vs-chip>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { PLATFORMS } from '@/constants/constants'
import colorsMixin from '@/mixins/colorsMixin'

export default {
  name: 'ChatContact',
  props: {
    contact: {
      type: Object,
      required: true
    },
    lastMessaged: {
      type: String,
      default: ''
    },
    unseenMsg: {
      type: Number,
      default: 0
    },
    highlight: {
      type: String,
      default: ''
    },
    selectedCategory: {
      type: Object
    }
  },
  mixins: [colorsMixin],
  data() {
    return {
      timeElapsed: null,
      timer: null
    }
  },
  components: {
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'activeChatUser']),
    fullname() {
      if (
        this.contact.fullname === 'Anonymous User' ||
        this.contact.fullname === 'Usuario Anónimo'
      ) {
        return this.lang.attentionRequests.sidenav.anonymousUser[
          this.languageSelected
        ]
      }
      // if (this.contact.fullname.length > this.MAX_FULLNAME_LENGHT) {
      //   return `${this.contact.fullname.substr(0, this.MAX_FULLNAME_LENGHT)}...`
      // }
      return this.contact.fullname
    },
    isActiveChatUser() {
      if (
        this.activeChatUser != null &&
        this.activeChatUser._id == this.contact._id
      ) {
        return true
      } else {
        return false
      }
    },
    chatStatusColor() {
      if (this.contact.status === 1) return 'warning'
      if (this.contact.status === 2) return 'success'
      if (this.contact.status === 3) return 'danger'
      return 'grey'
    },
    chatStatusText() {
      return this.lang.attentionRequests.chatStatus[this.contact.status][
        this.languageSelected
      ]
    },
    messageClass() {
      return {
        'bg-warning-gradient text-white':
          this.isActiveChatUser && this.activeChatUser.status === 1,
        'bg-success-gradient text-white':
          this.isActiveChatUser && this.activeChatUser.status === 2,
        'bg-danger-gradient text-white':
          this.isActiveChatUser &&
          (this.activeChatUser.status === 0 || this.activeChatUser.status === 3)
        // 'bg-custom-gradient text-white':
        //   this.isActiveChatUser && this.activeChatUser.status === 0
        // 'bg-custom-gradient text-white': this.isActiveChatUser
      }
    },
    // timeElapsed() {
    //   return this.contact._lastMessageAt
    //     ? this.$moment(this.contact._lastMessageAt).fromNow()
    //     : this.$moment(this.contact.ts).fromNow()
    // },
    timestamp() {
      return this.contact._lastMessageAt
        ? this.$moment(this.contact._lastMessageAt).format('DD/MM/YYYY, HH:mm')
        : this.$moment(this.contact.ts).format('DD/MM/YYYY, HH:mm')
    },
    platformName() {
      const platform = PLATFORMS.find(p => p.value === this.contact.platform)
      return platform ? platform.name : this.contact.platform
    },
    lastMessageAt() {
      return this.contact._lastMessageAt? this.contact._lastMessageAt : this.contact.ts
    }
  },
  methods: {
    channelLogo(channel) {
      let image = ''
      try {
        image = require(`@/assets/images/icons/channels/${channel.toLowerCase()}.svg`)
      } catch (error) {
        image = require(`@/assets/images/icons/channels/message-square.svg`)
      }
      return image
    },
    updateTimeElapsed() {
      const lastMsg = this.contact._lastMessageAt || this.contact.ts
      const moreThanAnHour =
        this.$moment().diff(this.$moment(lastMsg), 'minutes') >= 60
      this.timeElapsed = this.$moment(lastMsg).fromNow()
      if (moreThanAnHour) {
        clearInterval(this.timer)
        return
      }
    }
  },
  watch: {
    activeChatUser() {
      this.$forceUpdate()
    },
    contact() {
      this.$forceUpdate()
    },
    lastMessageAt(){
      this.updateTimeElapsed()
    }
  },
  mounted() {
    this.updateTimeElapsed()
    // updates timeElapsed each minute for an hour
    this.timer = setInterval(() => this.updateTimeElapsed(), 60000)
  }
}
</script>

<style lang="scss">
.chat-contact {
  display: flex;
  flex-direction: column;
  min-height: 105px;
  padding: 10px 15px;
  border-bottom: 1px solid #dae1e7;

  &__header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    .status-bot {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-right: 15px;
      .bot-name {
        color: #b0b0b0;
        margin-bottom: 6px;
        .name {
          display: flex;
          align-items: center;
          .env-tag {
            border: solid 1px #b0b0b0;
            color: #b0b0b0;
            max-height: 14px;
            line-height: 12px;
            font-size: 9px;
            padding: 0px 3px;
            border-radius: 3px;
          }
        }
      }
      .con-vs-chip {
        width: fit-content;
        min-height: 24px;
        min-width: unset;
        margin-bottom: 0;
      }
      // .bg-chip-transparent {
      //   &.vs-chip-success {
      //     background: rgba(var(--vs-success), 0.15);
      //     color: rgba(var(--vs-success), 1) !important;
      //   }
      //   &.vs-chip-warning {
      //     background: rgba(var(--vs-warning), 0.15);
      //     color: rgba(var(--vs-warning), 1) !important;
      //   }
      //   &.vs-chip-danger {
      //     background: rgba(var(--vs-danger), 0.15);
      //     color: rgba(var(--vs-danger), 1) !important;
      //   }
      //   &.vs-chip-primary {
      //     background: rgba(var(--vs-primary), 0.15);
      //     color: rgba(var(--vs-primary), 1) !important;
      //   }
      // }
    }
    .timestamp {
      display: flex;
      #kona-help-tooltip .feather-icon {
        padding: 0px 5px;
        color: #b0b0b0;
      }
      &__text {
        font-size: 0.8rem;
        color: #b0b0b0;
        text-align: right;
        padding-top: 2px;
      }
    }
    .feather-icon {
      color: rgba(var(--vs-warning), 1) !important;
    }
  }
  &__body {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    .platform-user {
      display: flex;
      align-items: center;
      .platform {
        color: #2c2c2c;
      }
      .user {
        color: #b0b0b0;
      }
    }
    .intent {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background-size: cover;
      transition: all 0.25s ease-in-out;
      background-image: url('../../../../../../assets/images/icons/support.svg');

      &:hover {
        background-image: url('../../../../../../assets/images/icons/support_blue.svg');
      }
    }
  }

  &.bg-success-gradient,
  &.bg-warning-gradient,
  &.bg-danger-gradient,
  &.bg-primary-gradient {
    .chat-contact__header {
      .status-bot {
        .bot-name {
          color: white;
          .name {
            display: flex;
            align-items: center;
            .env-tag {
              color: white;
              border-color: white;
            }
          }
        }
        .bg-chip-transparent {
          &.vs-chip-success,
          &.vs-chip-warning,
          &.vs-chip-danger,
          &.vs-chip-primary {
            border: solid 1px white;
            color: white !important;
          }
        }
      }
      .timestamp {
        #kona-help-tooltip .feather-icon,
        &__text {
          color: white;
        }
      }
    }
    .chat-contact__body {
      .platform-user {
        img {
          box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.4);
        }
        .platform,
        .user {
          color: white;
        }
      }
    }
  }
}
</style>
