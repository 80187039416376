import { sha256 } from 'js-sha256'
import {  has } from 'lodash'

export default {
  methods: {
    getTagColor(tag) {
      let activeClass=true;
      let text='';
      if(has(tag,"name")){
        text=tag.name;
        activeClass=false;
      }else{
        text=(tag && tag.text)?tag.text:''
      }
      return {
        [`tag-color-${this.getIndexStyle(text)}`]: activeClass,
        'tag-color-catalog':!activeClass,
      };
    },
    getStyle(bgColor,txtColor){
      return `background-color: ${bgColor} !important;
              color: ${txtColor} !important;`;
  
    },
    getIndexStyle(text){
      const hash = sha256(text);
      let number = 1;
      for (let i = 0; i < hash.length; i++) {
        number *= hash.charCodeAt(i)
      }
      number = Math.abs(number / 400)
      number = parseFloat(`${number.toString().substring(1, 5)}`)
      const index = Math.floor((number * 20)) + 1
      return index;
    },
    getTagColorCatalogVar(tag){
      return `--background-color: ${tag.bgColor} ;
      --text-color: ${tag.txtColor} ;`;
  
    },
    contrastColor(color) {
      {
        const lum = [1, 3, 5].map(pos => 
        {
          return parseInt(color.substr(pos, 2), 16);
        }).reduce((result, value, index) => {
          const y = [.299, .587, .114][index];
          return result + y * value 
        }
          , 0 );
        const isDark = lum < 128;
        const index = ~~isDark; 
        return ["#000000", "#ffffff"][index];
      }
    },
    rgbToHex(color) {
      color = "" + color;
      if (!color || color.indexOf("rgb") < 0) {
        return;
      }

      if (color.charAt(0) == "#") {
        return color;
      }

      var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
        r = parseInt(nums[2], 10).toString(16),
        g = parseInt(nums[3], 10).toString(16),
        b = parseInt(nums[4], 10).toString(16);

      return "#" + (
        (r.length == 1 ? "0" + r : r) +
        (g.length == 1 ? "0" + g : g) +
        (b.length == 1 ? "0" + b : b)
      );
    }
  }
}
